import React, {useState, useContext, useEffect} from "react";
import {withRouter} from "react-router";
import {
  sd_btn_loading,
  sd_dashboard_dob,
  SD_gallery_02,
  SD_Right_Arrow_white,
  sd_rupee,
  sd_script,
  sd_side_arrow,
} from "../../../../Assets/imageList";
import {SD_Context} from "../../../../context/sd-context";
// import DatePicker from "react-date-picker";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sd_DashboardService from "../../../../services/sd-dashboard.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import moment from "moment";
import {addDays} from "date-fns";
// import {DecryptInformation} from "../../../../Utils/sd-encrypt/encrypt";
toast.configure();

const Sd_dashboard_online_booking_accommodation = ({history}) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const {
    setActive,
    setActivePath,
    currentBookingAccomadations,
    setcurrentBookingAccomdations,
    logout,
    // devoteeList,
    // userData,
  } = useContext(SD_Context);

  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [startDate, setStartDate] = useState(addDays(new Date(), 1));
  const [reserved, setReserved] = useState({});
  const [ac, setAc] = useState("");
  const [beds, setBeds] = useState("");
  const [search, setSearch] = useState(false);
  const [err, setErr] = useState([]);
  const [first, setFirst] = useState(false);
  const [data, setData] = useState([]);
  // const [autoFill, setAutoFill] = useState("none");
  const [occupancy, setOccupancy] = useState(2);

  const [loading, setLoading] = useState();

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  useEffect(() => {
    if (
      new URLSearchParams(history?.location?.search).get("state") === "clear"
    ) {
      setcurrentBookingAccomdations(null);
    }
    setActive("online-booking-accommodations");
    setActivePath("Onlinebooking-Accommodations");
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (currentBookingAccomadations) {
      setStartDate(currentBookingAccomadations?.startDate);
      setOccupancy(currentBookingAccomadations?.occupancy);
      setAc(currentBookingAccomadations?.ac);
      setSearch(false);
      // setReserved(reserved);
    }
  }, []);
  useEffect(() => {
    if (occupancy !== "" && startDate !== "" && ac !== "") {
      findRooms();
    }
  }, [occupancy, startDate, ac]);

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//

  const handleSubmit = (item) => {
    setReserved(item);

    let data = {
      reserved: item,
      startDate,
      beds,
      ac,
      occupancy,
    };
    setcurrentBookingAccomdations(data);
    setTimeout(() => {
      history?.push(
        `/${selectedLanguage}/devotee-app/online-booking/accommodations/confirm`
      );
    }, 1000);
  };
  const getRoomsDetails = () => {
    if (data.length === 0) {
      return "No Rooms available";
    }
    return data.map((item, i) => {
      return (
        <div
          className="col-xs-12 col-sm-12 col-md-4 col-lg-4 xs-pl-0  xs-pr-0 "
          key={i}
        >
          <div className="sd-seva-box text-center">
            <img
              src={item?.image_url}
              alt={SD_gallery_02}
              className="sd-acc-img"
            />
            <div className="sd-side-heading-2 sd-acc">{item.block}</div>
            <p className="sd-seva-time">
              <span style={{marginRight: "3px", fontWeight: "600"}}>
                {item.available_rooms}
              </span>
              -<span style={{fontWeight: "400"}}> Available Rooms </span> <br />
              <span style={{fontWeight: "600"}}>
                <span style={{textTransform: "capitalize"}}>
                  {item?.room_type === "AC"
                    ? "AC"
                    : item?.room_type === "NONAC"
                    ? "Non A/C"
                    : item?.room_type === "SUITE"
                    ? "Suite"
                    : "COTTAGE"}
                </span>
              </span>
              <br></br>
            </p>
            <div className="clearfix mt-20">
              <span
                className="float-left sd-seva-cost"
                style={{whiteSpace: "nowrap"}}
              >
                <img
                  src={sd_rupee}
                  alt="Rs"
                  height="13px"
                  style={{marginTop: "2px"}}
                />
                <span style={{fontWeight: "600"}}>{item.price} </span>
                <span style={{fontWeight: "400"}}> / Day</span>
              </span>
              <div className="float-right">
                <a
                  className="sd-btn-orange"
                  onClick={() => {
                    handleSubmit(item);
                  }}
                >
                  Book Now
                  <img className="sd-arrow-btn ml-5" src={sd_side_arrow} />
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  const findRooms = async () => {
    setLoading(true);
    let a = true;
    if (a) {
      let data = [...err];
      if (startDate === "") {
        data.push({type: "date", msg: "Please Select a Date"});
      }

      if (ac === "") {
        data.push({type: "ac", msg: "Please Select a Type"});
      }
      setErr(data);
      if (data.length !== 0) {
        return;
      }
    }

    if (startDate === "" || ac === "") {
      toast.error("Please fill all the details and search");
      // setloading(false);
      return;
    }
    // console.log(moment(startDate).format("YYYY-MM-DD"));
    setReserved("");
    try {
      let res = await Sd_DashboardService.rooms_available(
        ac,
        moment(startDate).format("YYYY-MM-DD"),
        occupancy
      );
      setData(res);
      if (!first) {
        setFirst(true);
      }
      setSearch(true);
    } catch (e) {
      let data = [...err];
      data.push({type: "server", msg: e?.message});
      toast.warning(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/accommodations`
        );
      }
      setSearch("");
    }
    setLoading(false);
  };

  //-------------------------------------------------------------functions-------------------------------------------------------------//
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      <div className="sd-profile sd-seva-form">
        <h2 className="sd-side-heading fw400">
          <span className="fw700">Accommodation </span>Booking
        </h2>
        <div className="clearfix">
          {/* <p className="flex-c w-100 border-c pb-30  pt-20 br-4">
            <img
              src={sd_script}
              alt="#"
              height="80px"
              width="80px"
              className="mr-5 mb-5 opace"
            />
            We are sorry to say that accommodation service is temporarily
            unavailable due to Covid-19. We regret the inconvenience.
          </p> */}

          <form className="sd-form" style={{marginBottom: "10px"}}>
            <div className="sd-profile sd-acc-profile">
              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-0 xssm-pl-0 xssm-pr-0 pr-0 sd-acc-main">
                <div className={"sd-dob sd-acc-calender"}>
                  <DatePicker
                    allowSameDay={false}
                    style={{
                      width: "90%",
                      border: "none",
                      backgroundColor: "blue",
                    }}
                    inline
                    monthsShown={window.innerWidth < 600 ? 1 : 2}
                    minDate={addDays(new Date(), 1)}
                    maxDate={addDays(new Date(), 30)}
                    calendarIcon={<img src={sd_dashboard_dob} alt="" />}
                    clearIcon={null}
                    selected={startDate}
                    onChange={(date) => {
                      if (
                        err.find(
                          (e) => e.type === "date" || e.type === "server"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "date" && e.type !== "server") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setStartDate(date);
                      setData([]);
                    }}
                  />
                </div>

                {/* <div className=" sd-quota">
                  <div className="sd-quota-div">
                    <p
                      style={{
                        backgroundColor: "blue",

                        color: "blue",
                      }}
                    >
                      {""}
                    </p>
                    Available
                  </div>
                  <div className="sd-quota-div">
                    <p style={{backgroundColor: "red", color: "red"}}> {""}</p>{" "}
                    Quota Full
                  </div>
                  <div className="sd-quota-div">
                    <p style={{backgroundColor: "black", color: "black"}}>
                      {" "}
                      {""}
                    </p>{" "}
                    Quota Not released
                  </div>
                </div>
               */}
              </div>
              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0 sd-acc-sub">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0">
                  <label>Check in</label>
                  <label className="form-control sd-q-label">
                    8 AM, {moment(startDate).format("Do MMMM YYYY")}
                  </label>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0">
                  <label>Check out</label>
                  <label className="form-control sd-q-label">
                    8 AM ,{" "}
                    {moment(addDays(startDate, 1)).format("Do MMMM YYYY")}
                  </label>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0">
                  <label>
                    No. of persons <span className="sd-imp">*</span>
                  </label>
                  <select
                    className={
                      err?.find((e) => e.type === "occupancy")
                        ? "form-control sd-red"
                        : occupancy !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={occupancy}
                    onChange={(e) => {
                      if (e.target.value) {
                        if (
                          err.find(
                            (e) => e.type === "occupancy" || e.type === "server"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "occupancy" && e.type !== "server") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setOccupancy(e.target.value);
                      }
                    }}
                  >
                    <option
                      style={{
                        display: "none",
                      }}
                    >
                      Select No. of persons
                    </option>

                    <option value={"2"} key={"2"}>
                      2
                    </option>
                    <option value={"3"} key={"3"}>
                      3
                    </option>
                    <option value={"4"} key={"4"}>
                      4
                    </option>
                  </select>
                  <p style={{color: "#181818", fontWeight: "600"}}>
                    Free for Children under 10 years
                  </p>
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "occupancy")?.msg}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0">
                  <label>
                    Room Type<span className="sd-imp">*</span>
                  </label>
                  <select
                    className={
                      err?.find((e) => e.type === "ac")
                        ? "form-control sd-red"
                        : ac !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={ac}
                    onChange={(e) => {
                      if (e.target.value) {
                        if (
                          err.find(
                            (e) => e.type === "ac" || e.type === "server"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "ac" && e.type !== "server") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setData([]);
                        setSearch(false);
                        setLoading(false);
                        setFirst(false);
                        setAc(e.target.value);
                      }
                    }}
                  >
                    <option
                      style={{
                        display: "none",
                      }}
                    >
                      Select a room type
                    </option>
                    <option value={"NONAC"} key={"NONAC"}>
                      Non AC
                    </option>
                    <option value={"AC"} key={"AC"}>
                      AC
                    </option>
                    <option value={"SUITE"} key={"SUITE"}>
                      Suite
                    </option>
                    <option value={"COTTAGE"} key={"COTTAGE"}>
                      Cottage
                    </option>
                  </select>
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "ac")?.msg}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3 mt-20  xssm-pl-0 pr-0">
                  <a
                    className="sd-btn-orange"
                    style={{whiteSpace: "nowrap"}}
                    onClick={() => {
                      findRooms();
                    }}
                  >
                    Search
                    {loading ? (
                      <img className="sd-arrow-btn ml-5" src={sd_btn_loading} />
                    ) : (
                      <img
                        className="sd-arrow-btn ml-5 mt-2"
                        src={SD_Right_Arrow_white}
                      />
                    )}
                  </a>
                </div>
              </div>
            </div>

            <hr className="clearfix"></hr>

            {search === true ? (
              <div className="clearfix sd-seva-content">
                <h3
                  className="sd-side-heading fw400 mt-30 mr-30"
                  style={{paddingBottom: "10px"}}
                >
                  <span className="fw600">Search Results</span>
                </h3>
                <hr className="clearfix"></hr>
                <br />
                {getRoomsDetails()}
              </div>
            ) : first ? (
              <Sd_loading />
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Sd_dashboard_online_booking_accommodation);
