import React, {useState, useContext, useEffect, useRef} from "react";
import {
  sd_btn_loading,
  sd_dashboard_dob,
  SD_Dropdown_Arrow_icon,
  SD_Right_Arrow_white,
  sd_trash,
} from "../../../../Assets/imageList";
import "../../../../Utils/sd-styles/sd-css/main.css";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import IndiaTime from "../../../../Utils/sd-moment/IndiaTime";
import {SD_Context} from "../../../../context/sd-context";

import "../../../../Utils/sd-styles/sd-dashboard.scss";
//import "react-day-picker/lib/style.css";
// import moment from "moment";

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PratyakshaSevaService from "../../../../services/sd-pratyakshaseva.service";
import DatePicker from "react-date-picker";
import Sd_DashboardService from "../../../../services/sd-dashboard.service";
import moment from "moment";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import {
  DecryptInformation,
  EncryptInformation,
} from "../../../../Utils/sd-encrypt/encrypt";
import {BASE_BANK} from "../../../../Utils/sd-axios/sd-api-url";

const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const Sd_dashboard_pratyakshaseva_seva_details = ({history, match}) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const [loading, setLoading] = useState(true);
  const {
    setActivePath,
    setActive,
    currentBookingPratyaksha,
    setcurrentBookingPratyaksha,
    pratyakshaSevaData,
    setPratyakshaSevaData,
    devoteeList,
    userData,
    logout,
  } = useContext(SD_Context);
  const ref = useRef(null);
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const [noPersons, setNoperons] = useState(1);
  const [checkbox, setCheckbox] = useState(false);
  const [autoFill, setAutoFill] = useState("none");
  const [personStates, setPersonStates] = useState([
    {
      id: 1,
      name: "",
      gothram: "",
      idProofType: "",
      idProofNumber: "",
      doc_file_url: "",
      // covid_file_url: "",
      gender: "",
      dob: "",
    },
  ]);
  const [err, setErr] = useState([]);
  const [totalCost, setTotalCost] = useState("");
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedArray, setSelectedArray] = useState([]);
  const [slot, setSlot] = useState("");
  const [slotLoading, setSlotLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [abhishekamType, setAbhishekamType] = useState("single");
  const [slotsData, setSlotsData] = useState([]);

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------consts-----------------------------------------------------------//
  const termsList = [
    "For all Sevas & Pujas, Male sevadars shall wear Pancha with Kanduva and females shall wear Saree with Blouse invariably. Other dresses will not be allowed",
    "Only couple (Wife & Husband) or single person will be allowed to perform the Seva on one ticket.",
    "Children below 10 years of age are also permitted to follow their parents.",
    "Other kith and kin will not be allowed along with sevadars of any Seva ticket holders. Those kith and kin should take Rs.500/- ticket each to enter into the temple only for having Darshanam and not to perform the Seva",
    "An ID proof on the name of the ticket holder must also be shown.",
    "This ticket is non-transferable and it cannot be cancelled.",
    "Devotees are requested to collect the Abhisekam / Puja original tickets at Donation Counter before 9.00 P.M one day before the date of performance.",
    "Mobiles are strictly prohibited in Temple Premises.",
  ];
  const udhayastamana = [
    "On each ticket either couple/ individual is allowed.  If the couple is accompanied by children above 12 years, they will be allowed for Darshanam alone upon payment of Rs.500 and  Children are not allowed to participate in other sevas that are allowed to participated by the Couple under the Udayasthamana Seva.",
    "The devotees taking part in Udayasthamana Seva  need to mandatorily wear the traditional dress ( For Men: Dothi and towel and for women Saree and Blouse) ",
    "The Devotees need to carry original ID cards (Aadhaar/ Passport for NRI Devotees) for Udayasthamana Seva",
    "Udayasthamana Seva  is allowed only to Devotees who pre-booked their tickets and under no circumstances Devotees without ticket are not permitted. ",
    "Udayasthamana Seva tickets are not transferrable and linked with the ID Card used for the purchase of the Tickets",
    "Udayasthamana Seva tickets once purchased are not refundable and cannot be cancelled under any circumstances. Also, the Udayasthamana Seva tickets cannot be pre-poned or postponed under any circumstances",
    "Udayasthamana Seva if for any unavoidable reasons unable to attend, then the same may be post-phoned to next available date based on the availability of tickets on the said date",
    "Udayasthamana Seva devotees need to contact the cell number provided in the Ticket.",
    "Accommodation for Devotees of Udayasthamana Seva shall be provided from the previous day evening 06:00 p.m till next morning 09:00 a.m. The Accommodation is provided for Udayasthamana Seva Devotee Couple/Individual as the case may be in the  Suite Room and no additional beds are provided",
    "Udayasthamana Seva Devotees are allowed to participate in the specified seva programs only.",
    "The Devasthanam in its discretion can cancel or postpone the Udayasthamana Seva for any reasons and Devasthanam decision cannot be questioned; however, the Devotees who purchased the Udayasthamana Seva tickets can rescheduled the same in future date as per their convenience subject to availability of tickets.",
    "Udayasthamana Seva devotees need to contact the cell number provided in the Ticket",
  ];
  const pradhoshakala = [
    "On each ticket either couple/ individual is allowed.  If the couple is accompanied by children above 12 years, they will be allowed for Darshanam alone upon payment of Rs.500 and  Children are not allowed to participate in other sevas that are allowed to participated by the Couple under the Udayasthamana Seva.",
    "The devotees taking part in Pradoshakala Seva  need to mandatorily wear the traditional dress ( For Men: Dothi and towel and for women Saree and Blouse) ",
    "The Devotees need to carry original ID cards (Aadhaar/ Passport for NRI Devotees) for Pradoshakala Seva",
    "Pradoshakala Seva  is allowed only to Devotees who pre-booked their tickets and under no circumstances Devotees without ticket are not permitted. ",
    "Pradoshakala Seva tickets are not transferrable and linked with the ID Card used for the purchase of the Tickets",
    "Pradoshakala Seva tickets once purchased are not refundable and cannot be cancelled under any circumstances. Also, the Pradoshakala Seva tickets cannot be pre-poned or postponed under any circumstances",
    "Pradoshakala Seva if for any unavoidable reasons unable to attend, then the same may be post-phoned to next available date based on the availability of tickets on the said date",
    "Pradoshakala Seva devotees need to contact the cell number provided in the Ticket.",
    "Accommodation for Devotees of Pradoshakala Seva shall be provided for the same day",
    "Pradoshakala Seva Devotees are allowed to participate in the specified seva programs only.The Accommodation is provided for Udayasthamana Seva Devotee Couple/Individual as the case may be in the  A/C Room and no additional beds are provided",
    "The Devasthanam in its discretion can cancel or postpone the Pradoshakala Seva for any reasons and Devasthanam decision cannot be questioned; however, the Devotees who purchased the Pradoshakala Seva tickets can rescheduled the same in future date as per their convenience subject to availability of tickets.",
    "Pradoshakala Seva devotees need to contact the cell number provided in the Ticket",
  ];

  // const abhishekamTimes = ["6:30 AM", "12:30 PM", "6:30 PM"];

  // const kumkumarchanaTimes = [
  //   "6:30 AM - 7:30 AM",
  //   "7:30 AM - 8:30 AM",
  //   "8:30 AM - 9:30 AM",
  //   "9:30 AM - 10:30 AM",
  //   "10:30 AM - 11:30 AM",
  //   "11:30 PM - 12:30 PM",
  //   "12:30 PM - 1:30 PM",
  //   "1:30 PM -2:00 PM",
  // ];
  //-------------------------------------------------------------consts-----------------------------------------------------------//

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//
  useEffect(() => {
    setActive("online-booking-pratyaksha-enter-details");
    setActivePath("Onlinebooking-Pratyaksha Seva-Enter Details");
    setLoading(true);
    // if (!currentBookingPratyaksha) {
    //   history?.push(`/${selectedlanguage}/devotee-app/`);
    // }

    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (currentBookingPratyaksha) {
      currentBookingPratyaksha?.abhishekamType &&
        setAbhishekamType(currentBookingPratyaksha?.abhishekamType);
      setNoperons(currentBookingPratyaksha?.noPersons);
      setPersonStates(currentBookingPratyaksha?.personStates);
      setStartDate(currentBookingPratyaksha?.startDate);
      setSelectedArray(currentBookingPratyaksha?.selectedArray);
      currentBookingPratyaksha?.slot && setSlot(currentBookingPratyaksha?.slot);
      setSlot(currentBookingPratyaksha?.slot);
      setSlotsData(currentBookingPratyaksha?.slotsData);
      setLoading(false);
    } else {
      if (pratyakshaSevaData.length === 0) {
        getData();
      } else {
        let finder = pratyakshaSevaData.find(
          (item) =>
            item?.name?.replace?.(/\s/g, "-").toLowerCase() ===
            match.params.type
        );
        if (finder?.disabled) {
          history?.push(
            `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-seva`
          );
        }
        setSelectedArray([finder]);
        if (finder?.name === "Samuhika Abhishekamulu (Couple)") {
          setNoperons(2);
        }
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    listFunction();
  }, [noPersons]);
  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//

  const getData = async () => {
    try {
      let data = await PratyakshaSevaService.getAll();
      if (data) {
        setPratyakshaSevaData(data);
        let finder = data.find(
          (item) =>
            item?.name?.replace?.(/\s/g, "-").toLowerCase() ===
            match.params.type
        );
        if (finder?.disabled) {
          history?.push(
            `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-seva`
          );
        }
        setSelectedArray([finder]);
      }
      setLoading(false);
    } catch (e) {
      toast.warning(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/donations`
        );
      }
      setLoading(false);
    }
  };
  const handleFileUpload = async (e) => {
    try {
      if (e.target.files[0].size / 1024 / 1024 > 5) {
        // setUploadFlag(false);
        document.getElementById(`${e.target.id}`).value = "";

        throw new Error(
          "Please make sure the file you are attaching are below 5 mb "
        );
      }
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      let res = await Sd_DashboardService.upload_document(
        formData,
        "pratyakshaSeva"
      );
      toast.info("File Uploaded");
      return res;
    } catch (e) {
      toast.error(e.message);
      document.getElementById(`${e.target.id}`).value = "";

      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva`
        );
      }
      return false;
    }
    // setUploadFlag(false);
  };

  const buttonsArrayFunction = () => {
    return selectedArray.map((item, i) => {
      return (
        <div
          className="button-div"
          style={{display: "flex"}}
          key={(i = "buttons-prs")}
        >
          <p>
            {item?.name}
            <span className="mr-3">-</span>
            {item?.start_date && IndiaTime(item?.start_date).format("hh:mm A")}
            <span className="mr-3">-</span>
            {item?.end_date && IndiaTime(item?.end_date).format("hh:mm A")}{" "}
            <span className="mr-3">-</span> Rs.
            {item?.price}
          </p>
          {/* <h3
            onClick={() => {
              if (selectedArray.length === 1) {
                return;
              } else {
                let data = [...selectedArray];
                setTotalCost(totalCost - item.price);
                var filtered = data.filter(function (el) {
                  return el.name != item?.name;
                });
                setSelectedArray([...filtered]);
              }
            }}
          >
            X
          </h3> */}
        </div>
      );
    });
  };
  const listFunctionSevas = () => {
    // console.log("in pratyaksha seva");
    let temp = pratyakshaSevaData.filter((item) => !item.disabled);
    let array = temp.map((item, i) => {
      if (item?.disabled || item?.max_booking_per_day === 0) {
        return;
      } else {
        let finder = null;
        for (let i = 0; i < selectedArray.length; i++) {
          if (selectedArray[i].name === item?.name) {
            finder = true;
          }
        }
        return (
          <div
            key={i + "list-prs"}
            className={finder ? "option-class sd-cyan" : "option-class"}
            onClick={() => {
              setTotalCost(item.price);
              setSelectedArray([item]);
              if (item?.name === "Samuhika Abhishekamulu (Single)") {
                // console.log("single");
                setNoperons(1);
              } else if (item?.name === "Samuhika Abhishekamulu (Couple)") {
                // console.log("couple");
                setNoperons(2);
              }
              setSlot("");
              setSlotsData([]);
              setStartDate("");
              setShow(false);
            }}
          >
            {item?.name}
            {window.innerWidth < 600 ? <br /> : <span className="mr-3">-</span>}
            {IndiaTime(item?.start_date).format("hh:mm A") ===
            IndiaTime(item?.end_date).format("hh:mm A") ? (
              <>
                {item?.start_date &&
                  IndiaTime(item?.start_date).format("hh:mm A")}
              </>
            ) : (
              <>
                {item?.start_date &&
                  IndiaTime(item?.start_date).format("hh:mm A")}{" "}
                <span className="mr-3">-</span>
                {item?.end_date && IndiaTime(item?.end_date).format("hh:mm A")}
              </>
            )}
            <span className="mr-3">-</span> Rs.
            {item.price}
          </div>
        );
      }
    });
    return array;
  };
  const fnCalculateAge = (userDateinput) => {
    let birthDate = new Date(userDateinput);
    let difference = Date.now() - birthDate.getTime();
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    return calculatedAge;
  };
  const handleChange = (index, event, name) => {
    // let data = [...personStates];
    // console.log(data);
    if (
      name === "doc_file_url" ||
      name === "dob"
      // name === "covid_file_url"
    ) {
      let data = [...personStates];
      data[index][`${name}`] = event;
      setPersonStates(data);
    } else {
      if (name === "idProofType") {
        let data = [...personStates];

        let value = data[index][`${name}`];
        data[index][`${name}`] = event.target.value;
        if (value !== event.target.value) {
          data[index][`doc_file_url`] = "";
          data[index][`idProofNumber`] = "";
        }
        setPersonStates(data);
      } else if (name === "idProofNumber") {
        let data = [...personStates];
        data[index][`idProofNumber`] = event.target.value
          ?.replaceAll?.(" ", "")
          ?.toUpperCase();
        setPersonStates(data);
      } else {
        let data = [...personStates];
        data[index][`${name}`] = event.target.value;
        setPersonStates(data);
      }

      // if (data[index][name]) {
      //   data[index][name] = event.target.value;
      // }
    }
    // console.log(index, event);
    // let datas = [...data];
    // setPersonStates(datas);
  };
  const listFunction = () => {
    if (noPersons === personStates.length) {
      return;
    }
    if (noPersons < personStates.length) {
      let arr = [...personStates];

      let data = err.filter((e) => {
        if (
          e.type !== `${personStates.length}-name` &&
          e.type !== `${personStates.length}-gothram` &&
          e.type !== `${personStates.length}-idProofType` &&
          e.type !== `${personStates.length}-idProofNumber` &&
          e.type !== `${personStates.length}-doc_file_url` &&
          e.type !== `${personStates.length}-gender` &&
          e.type !== `${personStates.length}-dob`
          // &&
          // e.type !== `${personStates.length}-covid_file_url`
        ) {
          return e;
        }
      });
      setErr(data);
      arr.pop();

      setPersonStates([...arr]);
      return;
    }
    if (noPersons > personStates.length) {
      // console.log(noPersons);
      // console.log("greater");
      // console.log(personStates);
      let arr = [...personStates];
      // for (let i = personStates.length; i < noPersons; i++) {
      arr.push({
        id: personStates.length + 1,
        name: "",
        gothram: "",
        idProofType: "",
        idProofNumber: "",
        doc_file_url: "",
        gender: "",
        dob: "",
        // covid_file_url: "",
      });
      setPersonStates([...arr]);
      return;
    }
    // }
  };
  function addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }
  const autoFillFunction = (name, i) => {
    let array = [];
    let userOnClick = () => {
      let data = [...personStates];
      data[i]["name"] =
        userData?.first_name || userData?.last_name
          ? `${userData?.first_name} ${userData?.last_name}`
          : "";
      data[i]["dob"] = userData?.date_of_birth
        ? new Date(userData?.date_of_birth)
        : "";
      data[i]["gender"] = userData?.gender ? userData?.gender : "";
      data[i]["gothram"] = userData?.gothram ? userData?.gothram : "";
      data[i]["doc_file_url"] = userData?.doc_file_url
        ? userData?.doc_file_url
        : "";
      data[i]["idProofType"] = userData?.id_proof_type
        ? userData?.id_proof_type
        : "";
      data[i]["idProofNumber"] = userData?.id_proof_number
        ? DecryptInformation(userData?.id_proof_number)
        : "";
      setPersonStates(data);
      let datas = err.filter((e) => {
        if (
          e.type !== `${i + 1}-gothram` &&
          e.type !== `${i + 1}-name` &&
          e.type !== `${i + 1}-dob` &&
          e.type !== `${i + 1}-gender` &&
          e.type !== `${i + 1}-doc_file_url` &&
          e.type !== `${i + 1}-idProofNumber` &&
          e.type !== `${i + 1}-idProofType`
        ) {
          return e;
        }
      });
      setErr(datas);
      setAutoFill("none");
    };
    let a = personStates.filter((e) => {
      if (
        e?.name?.toLowerCase() ===
        `${userData?.first_name?.toLowerCase()} ${userData?.last_name?.toLowerCase()}`
      ) {
        return e;
      }
    });

    if (
      userData?.first_name?.toLowerCase()?.includes(name?.toLowerCase()) ||
      userData?.last_name?.toLowerCase()?.includes(name?.toLowerCase())
    ) {
      if (a?.length === 0) {
        array.push(
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{textTransform: "capitalize"}}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.first_name
                ? userData?.first_name
                : userData?.display_name}
              <span style={{marginLeft: "3px"}}>{userData?.last_name}</span>
            </p>
            {userData?.date_of_birth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.date_of_birth)} yrs
              </p>
            )}
            {userData?.gender && <p>Gender: {userData?.gender}</p>}
          </p>
        );
      }
    }
    if (devoteeList !== null) {
      devoteeList.forEach((items) => {
        if (
          items?.first_name?.toLowerCase()?.includes(name?.toLowerCase()) ||
          items?.last_name?.toLowerCase()?.includes(name?.toLowerCase())
        ) {
          let a = personStates.filter((e) => {
            if (e?.name === `${items?.first_name} ${items?.last_name}`) {
              return e;
            }
          });
          if (a?.length === 0) {
            let onCLick = () => {
              let data = [...personStates];
              data[i]["name"] =
                items?.first_name || items?.last_name
                  ? `${items?.first_name} ${items?.last_name}`
                  : "";
              data[i]["dob"] = items?.dob ? new Date(items?.dob) : "";
              data[i]["gender"] = items?.gender
                ? items?.gender?.toLowerCase()
                : "";
              data[i]["gothram"] = items?.gothram ? items?.gothram : "";
              data[i]["doc_file_url"] = items?.doc_file_url
                ? items?.doc_file_url
                : "";
              data[i]["idProofType"] = items?.id_proof_type
                ? items?.id_proof_type
                : "";
              data[i]["idProofNumber"] = items?.id_proof_number
                ? DecryptInformation(items?.id_proof_number)
                : "";
              setPersonStates(data);
              let datas = err.filter((e) => {
                if (
                  e.type !== `${i + 1}-gothram` &&
                  e.type !== `${i + 1}-name` &&
                  e.type !== `${i + 1}-dob` &&
                  e.type !== `${i + 1}-gender` &&
                  e.type !== `${i + 1}-doc_file_url` &&
                  e.type !== `${i + 1}-idProofNumber` &&
                  e.type !== `${i + 1}-idProofType`
                ) {
                  return e;
                }
              });
              setErr(datas);
              setAutoFill("none");
            };
            array.push(
              <p
                key={items.id + "autofill-prs"}
                className="sd-autofill-div"
                onClick={() => {
                  onCLick();
                }}
              >
                <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Name: {items?.first_name}
                  <span style={{marginLeft: "3px"}}>{items?.last_name}</span>
                </p>
                <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Age:
                  {fnCalculateAge(items?.dob)} yrs
                </p>
                <p>Gender: {items?.gender}</p>
              </p>
            );
          }
        }
      });
    }

    if (array.length !== 0) {
      return (
        <div className="sd-autofill bg">
          {array.map((item) => {
            return item;
          })}
        </div>
      );
    } else {
      if (name === "" && a?.length === 0) {
        let demo = (
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{textTransform: "capitalize"}}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.first_name
                ? userData?.first_name
                : userData?.display_name}
              <span style={{marginLeft: "3px"}}>{userData?.last_name}</span>
            </p>
            {userData?.date_of_birth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.date_of_birth)} yrs
              </p>
            )}
            {userData?.gender && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Gender: {userData?.gender}
              </p>
            )}
          </p>
        );

        let a = [...devoteeList, demo];
        return (
          <div className="sd-autofill bg ani">
            {a.map((item) => {
              return item;
            })}{" "}
          </div>
        );
      } else {
        return "";
      }
    }
  };
  const getSlots = async (id, date) => {
    setSlotLoading(true);

    try {
      setSlot("");
      setSlotsData([]);
      let res = await ParokshaSevaService.getPratyakshaSlots(id, date);
      // console.log(res);
      setSlotsData(res);
      setSlotLoading(false);
    } catch (e) {
      setSlotLoading(false);
      setSlot("");
      setSlotsData([]);
      toast.error(e.message);
    }
  };

  const getSlotsDetails = () => {
    if (slotsData.length === 0 || !slotsData) {
      return <p>No Slots available</p>;
    }
    return slotsData.map((item, i) => {
      if (item?.available_slots < 1) {
        return;
      }
      return (
        <div
          key={i + "slots-prs"}
          onClick={() => {
            if (item?.available_slots < 1) {
              return;
            }
            setSlot(item);
            if (err?.find((e) => e.type === `slot`)) {
              let data = err.filter((e) => e.type !== "slot");
              setErr(data);
            }
          }}
        >
          <div
            className="sd-darshanam-box text-center"
            style={{
              display: "flex",
              alignItems: "center",
              border:
                err.find((e) => e.type === "slot") || item?.available_slots < 1
                  ? "1px solid red"
                  : "",
            }}
            onClick={() => {
              if (personStates?.length > item?.available_slots) {
                return;
              }
              setSlot(item);
              if (err?.find((e) => e.type === `slot`)) {
                let data = err.filter((e) => e.type !== "slot");
                setErr(data);
              }
            }}
          >
            <p
              className="sd-darshanam-time"
              style={{marginBottom: "0px", maxWidth: "85px"}}
            >
              <span style={{marginRight: "3px", fontWeight: "600"}}>
                {item.available_slots}
              </span>
              <span style={{fontWeight: "400"}}> Available </span>
            </p>
            <div className="sd-side-heading-2 sd-acc">
              {window.innerWidth < 650 ? (
                <span
                  style={{
                    fontWeight: "600",
                    whiteSpace: "nowrap",
                  }}
                >
                  Slot <br /> (
                  {moment(item?.start_time, "HH:mm:ss").format("hh:mm A") ===
                  moment(item?.end_time, "HH:mm:ss").format("hh:mm A") ? (
                    moment(item?.start_time, "HH:mm:ss").format("hh:mm A")
                  ) : (
                    <>
                      {moment(item?.start_time, "HH:mm:ss").format("hh:mm A")}{" "}
                      to
                      <span style={{marginLeft: "5px"}}>
                        {moment(item?.end_time, "HH:mm:ss").format("hh:mm A")})
                      </span>
                    </>
                  )}
                </span>
              ) : (
                <span
                  style={{
                    fontWeight: "600",
                    whiteSpace: "nowrap",
                  }}
                >
                  Slot - (
                  {moment(item?.start_time, "HH:mm:ss").format("hh:mm A") ===
                  moment(item?.end_time, "HH:mm:ss").format("hh:mm A") ? (
                    moment(item?.start_time, "HH:mm:ss").format("hh:mm A")
                  ) : (
                    <>
                      {" "}
                      {moment(item?.start_time, "HH:mm:ss").format("hh:mm A")} -
                      {moment(item?.end_time, "HH:mm:ss").format("hh:mm A")})
                    </>
                  )}
                </span>
              )}
            </div>
            <input
              className="sd-darshanam-radio"
              type="radio"
              id={"slot" + 11}
              name=""
              value={slot}
              onClick={() => {
                if (personStates?.length > item?.available_slots) {
                  return;
                }
                setSlot(item);
                if (err?.find((e) => e.type === `slot`)) {
                  let data = err.filter((e) => e.type !== "slot");
                  setErr(data);
                }
              }}
              checked={slot.id === item?.id}
            />
          </div>
        </div>
      );
    });
  };
  const handleSubmit = async () => {
    setLoading(true);
    let a = true;
    if (a) {
      let data = [...err];

      if (!checkbox) {
        data.push({type: "check", msg: "Please agree to terms and conditions"});
      }
      if (startDate === "") {
        data.push({type: "date", msg: "Please select a date"});
      }
      if (slot === "") {
        data.push({type: "slot", msg: "Please select a slot"});
      }
      personStates.map((item) => {
        for (var key of Object.keys(item)) {
          if (item[key] === "") {
            if (key === "idProofType") {
              data.push({
                type: `${item["id"]}-${key}`,
                msg: `Select a id Proof`,
              });
            } else if (key === "idProofNumber") {
              data.push({
                type: `${item["id"]}-${key}`,
                msg: `Invalid id number`,
              });
            } else if (key === "doc_file_url") {
              data.push({type: `${item["id"]}-${key}`, msg: `Upload a File`});
            }
            // else if (key === "covid_file_url") {
            //   data.push({
            //     type: `${item["id"]}-${key}`,
            //     msg: `Upload a covid certificate`,
            //   });
            // }
            else {
              data.push({type: `${item["id"]}-${key}`, msg: `Invalid ${key}`});
            }
          } else {
            if (key === "name") {
              if (item["name"].length < 3) {
                data.push({
                  type: `${item["id"]}-name`,
                  msg: "Minimun 3 characters",
                });
              }
            }
            if (key === "gothram") {
              if (item["gothram"].length < 3) {
                data.push({
                  type: `${item["id"]}-gothram`,
                  msg: "Minimun 3 characters",
                });
              }
            }
            if (key === "idProofType") {
              if (item["idProofNumber"] !== "") {
                if (item["idProofType"] === "pan") {
                  let txt = item["idProofNumber"].toUpperCase();
                  let regex =
                    /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
                  let pan = {
                    C: "Company",
                    P: "Personal",
                    H: "Hindu Undivided Family (HUF)",
                    F: "Firm",
                    A: "Association of Persons (AOP)",
                    T: "AOP (Trust)",
                    B: "Body of Individuals (BOI)",
                    L: "Local Authority",
                    J: "Artificial Juridical Person",
                    G: "Govt",
                  };
                  pan = pan[txt[3]];
                  if (regex.test(txt)) {
                    if (pan !== "Personal") {
                      data.push({
                        type: `${item["id"]}-idProofNumber`,
                        msg: "Invalid Pan Number",
                      });
                    }
                  } else
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid Pan Number",
                    });
                } else if (item["idProofType"] === "ration") {
                  let regex = /^([a-zA-Z0-9]){8,12}\s*$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid ration number",
                    });
                  }
                } else if (item["idProofType"] === "driving") {
                  let regex =
                    /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    // data.push({
                    //   type: `${item["id"]}-idProofNumber`,
                    //   msg: "Invalid driving license number",
                    // });
                  }
                } else if (item["idProofType"] === "voter") {
                  let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid voter id",
                    });
                  }
                } else if (item["idProofType"] === "aadhar") {
                  let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

                  if (!regex.test(item["idProofNumber"])) {
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid AADHAR number",
                    });
                  }
                }
                if (item["doc_file_url"] === "") {
                  data.push({
                    type: `${item["id"]}-doc_file_url`,
                    msg: "Please attach a valid id proof",
                  });
                }
              }
            }
          }
        }
      });
      setErr(data);
      if (data.length !== 0) {
        setLoading(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return;
      }
    }

    try {
      setLoading(true);
      let dup = [...personStates];
      let arrayEx = dup.map((e) => {
        return {
          name: e?.name,
          dob: moment(e.dob).format("YYYY-MM-DD"),
          gender: e.gender,
          idProofType: e.idProofType,
          idProofNumber: EncryptInformation(e.idProofNumber),
          gothram: e.gothram,
          doc_file_url: e.doc_file_url.path
            ? e.doc_file_url.path
            : e.doc_file_url,
          // covid_certificate: e.covid_file_url.path
          //   ? e.covid_file_url.path
          //   : e.covid_file_url,
        };
      });

      let schema = [
        {
          sevaPersonDetails: arrayEx,
          itemType: "pratyakshaSeva",
          bookingDate: moment(startDate).format("YYYY-MM-DD"),
          itemId: selectedArray[0]?.id,
          slotId: slot?.id,
        },
      ];
      let res = await ParokshaSevaService.bookings(schema, BASE_BANK, true);
      if (res) {
        let data = {
          selectedArray,
          startDate,
          noPersons,
          personStates,
          slot,
          slotsData,
        };

        setcurrentBookingPratyaksha(data);
        history?.push(
          `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-seva/confirm-details`
        );
      }
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva`
        );
      }
      setLoading(false);

      return;
    }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//

  return (
    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard">
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="col-xs-12 col-sm-12 col-md-11 col-lg-11 pl-0 xs-pr-0 sd-profile sd-seva-form">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700" ref={ref}>
                Pratyaksha
              </span>{" "}
              Seva
            </span>
          </h2>
          <div className="clearfix">
            <form className="sd-form">
              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 pr-0">
                <label>
                  Select a Seva<span className="sd-imp">*</span>
                </label>

                <div className="select-div">
                  <div
                    className={
                      selectedArray.length > 0
                        ? "constant-top sd-white sd-voilet-border"
                        : "constant-top "
                    }
                  >
                    <div className="sd-buttonArray">
                      {buttonsArrayFunction()}
                    </div>

                    <div className="sd-input-image">
                      <input
                        className={selectedArray.length > 0 ? "sd-white" : " "}
                        type="text"
                        onClick={() => {
                          setShow(!show);
                        }}
                        onChange={(e) => {
                          setSelectedValue({value: e.target.value});
                        }}
                      />
                      <img
                        className={show ? "sd-image-invert" : ""}
                        src={SD_Dropdown_Arrow_icon}
                        alt=""
                        onClick={() => {
                          setShow(!show);
                        }}
                      />
                    </div>
                  </div>

                  {show && (
                    <div className="option-div" style={{paddingTop: "1vh"}}>
                      {listFunctionSevas()}
                    </div>
                  )}
                </div>
                {selectedArray.find(
                  (e) =>
                    e?.name ===
                    "Abhishekam Alamkaramandapam (Couple) Only Alamkara Darshanam"
                ) && (
                  <p style={{color: "red"}}>
                    Note : Due to devotee rush on going in this Karthika Masam,
                    devotees are allowed to make Alankara Dharshanam only.
                  </p>
                )}
                {selectedArray.find(
                  (e) => e?.name === "Samuhika Abhishekamulu (Couple)"
                ) && (
                  <p style={{color: "red"}}>
                    Note : Kind attention Devotees. Due to Heavy Rush during
                    Kartheeka Masam, the Sparsha Darsanam of Sri Mallikarjuna
                    Swamy and Garbhalaya Abhishekams are being stopped on
                    Sundays, Mondays and Kartheeka Pournami from 05.11.2021 to
                    04.12.2021.
                  </p>
                )}
                {selectedArray.find(
                  (e) => e?.name === "VIP Break Darshanam"
                ) && (
                  <p style={{color: "red"}}>
                    Note : Kind attention Devotees. Due to Heavy Rush during
                    Kartheeka Masam, the Sparsha Darsanam Tickets of Sri
                    Mallikarjuna Swamy and Garbhalaya Abhishekams Tickets are
                    freezed on Sundays, Mondays and Kartheeka Pournami from
                    05.11.2021 to 04.12.2021.
                  </p>
                )}
              </div>
              <hr className="clearfix"></hr>
              <div className="sd-profile">
                <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3 xssm-pr-0 pl-0">
                  <label>
                    Select Date<span className="sd-imp">*</span>
                  </label>

                  <div
                    className={
                      err?.find((e) => e.type === `date`)
                        ? "sd-dob customDatePickerWidth sd-red"
                        : startDate !== ""
                        ? "sd-date-voilet sd-dob customDatePickerWidth"
                        : "sd-dob customDatePickerWidth"
                    }
                  >
                    <DatePicker
                      dayPlaceholder="DD"
                      monthPlaceholder="MM"
                      yearPlaceholder="YYYY"
                      style={{
                        width: "90%",
                        border: "none",
                        backgroundColor: "blue",
                      }}
                      format="dd-MM-y"
                      minDate={addDays(new Date(), selectedArray[0].min_days)}
                      maxDate={addDays(new Date(), selectedArray[0].max_days)}
                      calendarIcon={<img src={sd_dashboard_dob} alt="" />}
                      clearIcon={null}
                      value={startDate}
                      onChange={(date) => {
                        if (err.find((e) => e.type === "date")) {
                          let data = err.filter((e) => e.type !== "date");
                          setErr(data);
                        }
                        getSlots(
                          selectedArray[0]?.id,
                          moment(date).format("YYYY-MM-DD")
                        );
                        setStartDate(date);
                      }}
                    />
                  </div>
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "date")?.msg}
                  </p>
                </div>
                {/* {selectedArray[0].name === "Samuhika Abhishekamulu (Single)" ||
                selectedArray[0].name === "Samuhika Abhishekamulu (Couple)" ||
                selectedArray[0].name ===
                  "Kumkumarchana (In Aseervachana Mandapam)" ? (
                  <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0">
                    <label>
                      Select a Slot<span className="sd-imp">*</span>
                    </label>
                    <select
                      className={
                        err.find((e) => e.type === "slot")
                          ? "form-control sd-red"
                          : slot !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={slot}
                      onChange={(e) => {
                        if (err.find((e) => e.type === "slot")) {
                          let data = err.filter((e) => e.type !== "slot");
                          setErr(data);
                        }
                        setSlot(e.target.value);
                      }}
                    >
                      {selectedArray[0].name ===
                        "Samuhika Abhishekamulu (Single)" ||
                      selectedArray[0].name ===
                        "Samuhika Abhishekamulu (Couple)"
                        ? abhishekamTimes.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))
                        : selectedArray[0].name ===
                          "Kumkumarchana (In Aseervachana Mandapam)"
                        ? kumkumarchanaTimes.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                ) : (
                  ""
                )} */}

                {selectedArray[0]?.name === "Samuhika Abhishekamulu (Single)" ||
                selectedArray[0]?.name === "Samuhika Abhishekamulu (Couple)" ? (
                  ""
                ) : (
                  <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pl-0 xssm-pr-0">
                    <label>
                      Number of Person(s)<span className="sd-imp">*</span>
                    </label>
                    <select
                      className={
                        noPersons !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={noPersons}
                      onChange={(e) => {
                        if (
                          selectedArray[0]?.name ===
                            "Samuhika Abhishekamulu (Single)" ||
                          selectedArray[0]?.name ===
                            "Samuhika Abhishekamulu (Couple)" ||
                          selectedArray[0]?.name === "VIP Break Darshanam"
                        ) {
                          if (
                            selectedArray[0]?.name ===
                              "Samuhika Abhishekamulu (Single)" ||
                            selectedArray[0]?.name === "VIP Break Darshanam"
                          ) {
                            setNoperons(1);
                          } else if (
                            selectedArray[0]?.name ===
                            "Samuhika Abhishekamulu (Couple)"
                          ) {
                            setNoperons(2);
                          }
                        } else {
                          setNoperons(e.target.value);
                        }
                      }}
                    >
                      <option key={1} value={1}>
                        Single
                      </option>
                      <option key={2} value={2}>
                        Couple
                      </option>
                    </select>
                    <p>Free for Children under 10 years</p>
                  </div>
                )}
              </div>
              {slotsData?.length !== 0 ? (
                <>
                  <hr className="clearfix"></hr>

                  <div
                    className="clearfix sd-darshanam-content"
                    style={{position: "relative"}}
                  >
                    <br />
                    {getSlotsDetails()}
                    <p
                      style={{
                        position: "absolute",
                        top: "-12px",
                        left: "0px",
                        textAlign: "right",
                        color: "red",
                      }}
                    >
                      {err?.find((e) => e.type === `slot`)?.msg}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  {startDate && !slotLoading ? (
                    <>
                      <hr className="clearfix"></hr>{" "}
                      <div
                        className="clearfix sd-darshanam-content"
                        style={{position: "relative", color: "red"}}
                      >
                        No Slots available on{" "}
                        {moment(startDate).format("DD-MM-YYYY")}
                      </div>
                    </>
                  ) : (
                    startDate && (
                      <>
                        <hr className="clearfix"></hr>{" "}
                        <div
                          className="clearfix sd-darshanam-content"
                          style={{position: "relative"}}
                        >
                          Fetching slots...Please wait{" "}
                        </div>
                      </>
                    )
                  )}
                </>
              )}
              <div className="clearfix">
                <hr className="clearfix"></hr>
              </div>{" "}
              {personStates.map((item, i) => {
                return (
                  <>
                    {" "}
                    <div className="clearfix" key={i + "personstates-prs"}>
                      <div
                        className="form-group col-xs-12 col-sm-12 col-md-1 col-lg-1  xssm-pr-0 pl-0"
                        style={{width: "2vw"}}
                      >
                        <label>{i + 1}</label>
                      </div>
                      <div className="form-group col-xs-12 col-sm-12 col-md-11 col-lg-11  xssm-pl-0 pr-0">
                        <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pr-0 pl-0">
                          <label>
                            Name<span className="sd-imp">*</span>
                          </label>
                          <input
                            maxLength="15"
                            autoComplete="new-password"
                            onFocus={() => {
                              if (autoFill === `${i}autofill`) {
                                setAutoFill("none");
                              } else {
                                setAutoFill(`${i}autofill`);
                              }
                            }}
                            onBlur={() => {
                              if (autoFill === `${i}autofill`) {
                                setTimeout(() => {
                                  setAutoFill("none");
                                }, 250);
                              }
                            }}
                            placeholder="Please enter your name"
                            type="text"
                            className={
                              err?.find((e) => e.type === `${i + 1}-name`)
                                ? "form-control sd-red"
                                : item?.name !== ""
                                ? "form-control sd-voilet"
                                : "form-control"
                            }
                            value={item?.name}
                            onChange={(e) => {
                              if (
                                /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                                e.target.value === ""
                              ) {
                                if (
                                  err.find((e) => e.type === `${i + 1}-name`)
                                ) {
                                  let data = err.filter(
                                    (e) => e.type !== `${i + 1}-name`
                                  );
                                  setErr(data);
                                }
                                handleChange(i, e, "name");
                              }
                            }}
                          />
                          <p className="sd-EM mr-15 ">
                            {err.find((e) => e.type === `${i + 1}-name`)?.msg}
                          </p>
                          {autoFill === `${i}autofill` &&
                            autoFillFunction(item?.name, i)}
                        </div>
                        <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0 ">
                          <label>
                            Date of Birth<span className="sd-imp">*</span>
                          </label>
                          <div
                            className={
                              err?.find((e) => e.type === `${i + 1}-dob`)
                                ? "sd-dob customDatePickerWidth  sd-red"
                                : item?.dob !== ""
                                ? "sd-dob customDatePickerWidth  sd-date-voilet"
                                : "sd-dob customDatePickerWidth"
                            }
                          >
                            <DatePicker
                              dayPlaceholder="DD"
                              monthPlaceholder="MM"
                              yearPlaceholder="YYYY"
                              style={{
                                width: "90%",
                                border: "none",
                                backgroundColor: "blue",
                              }}
                              format="dd-MM-y"
                              maxDate={addDays(new Date(), -2555)}
                              calendarIcon={
                                <img src={sd_dashboard_dob} alt="" />
                              }
                              clearIcon={null}
                              value={item?.dob}
                              onChange={(date) => {
                                if (
                                  err.find((e) => e.type === `${i + 1}-dob`)
                                ) {
                                  let data = err.filter(
                                    (e) => e.type !== `${i + 1}-dob`
                                  );
                                  setErr(data);
                                }
                                handleChange(i, date, "dob");
                              }}
                            />
                          </div>
                          <p className="sd-EM mr-15 ">
                            {err.find((e) => e.type === `${i + 1}-dob`)?.msg}
                          </p>
                        </div>
                        <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0">
                          <label>
                            Gender<span className="sd-imp">*</span>
                          </label>
                          <select
                            className={
                              err?.find((e) => e.type === `${i + 1}-gender`)
                                ? "form-control sd-red"
                                : item?.gender !== ""
                                ? "form-control sd-voilet"
                                : "form-control"
                            }
                            value={item?.gender}
                            onChange={(e) => {
                              if (
                                err.find((e) => e.type === `${i + 1}-gender`)
                              ) {
                                let data = err.filter(
                                  (e) => e.type !== `${i + 1}-gender`
                                );
                                setErr(data);
                              }
                              handleChange(i, e, "gender");
                            }}
                          >
                            {" "}
                            <option style={{display: "none"}}>
                              {" "}
                              Select a gender
                            </option>
                            <option key="male" value="male">
                              Male
                            </option>
                            <option key="female" value="female">
                              Female
                            </option>
                            <option key="others" value="others">
                              Others
                            </option>
                          </select>
                          <p className="sd-EM mr-15">
                            {err.find((e) => e.type === `${i + 1}-gender`)?.msg}
                          </p>
                        </div>
                        <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0  pr-0">
                          <label>
                            Gothram<span className="sd-imp">*</span>
                          </label>
                          <input
                            maxLength="30"
                            type="text"
                            placeholder="Please enter your gothram."
                            className={
                              err?.find((e) => e.type === `${i + 1}-gothram`)
                                ? "form-control sd-red"
                                : item?.gothram !== ""
                                ? "form-control sd-voilet"
                                : "form-control"
                            }
                            value={item?.gothram}
                            onChange={(e) => {
                              if (
                                /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                                e.target.value === ""
                              ) {
                                if (
                                  err.find((e) => e.type === `${i + 1}-gothram`)
                                ) {
                                  let data = err.filter(
                                    (e) => e.type !== `${i + 1}-gothram`
                                  );
                                  setErr(data);
                                }
                                handleChange(i, e, "gothram");
                              }
                            }}
                          />
                          <p className="sd-EM ">
                            {
                              err.find((e) => e.type === `${i + 1}-gothram`)
                                ?.msg
                            }
                          </p>
                        </div>
                        <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  pl-0  xssm-pr-0">
                          <label>
                            Photo ID Proof<span className="sd-imp">*</span>
                          </label>
                          <select
                            className={
                              err?.find(
                                (e) => e.type === `${i + 1}-idProofType`
                              )
                                ? "form-control sd-red"
                                : item.idProofType !== ""
                                ? "form-control sd-voilet"
                                : "form-control"
                            }
                            value={item?.idProofType}
                            onChange={(e) => {
                              if (
                                err.find(
                                  (e) => e.type === `${i + 1}-idProofType`
                                )
                              ) {
                                let data = err.filter(
                                  (e) => e.type !== `${i + 1}-idProofType`
                                );
                                setErr(data);
                              }

                              handleChange(i, e, "idProofType");
                            }}
                          >
                            <option style={{display: "none"}}>
                              Select a valid id proof
                            </option>

                            <option key="pan" value="pan">
                              PAN
                            </option>
                            <option key="aadhar" value="aadhar">
                              AADHAR
                            </option>
                            <option key="driving" value="driving">
                              Driving License
                            </option>
                            <option key="ration" value="ration">
                              Ration Card
                            </option>
                            <option key="voter" value="voter">
                              Voter ID
                            </option>
                          </select>
                          <p className="sd-EM mr-15">
                            {
                              err.find((e) => e.type === `${i + 1}-idProofType`)
                                ?.msg
                            }
                          </p>
                        </div>
                        <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3   xssm-pl-0 xssm-pr-0">
                          <label>
                            Photo ID Number<span className="sd-imp">*</span>
                          </label>

                          <input
                            placeholder="Please enter your id number"
                            type="text"
                            className={
                              err?.find(
                                (e) => e.type === `${i + 1}-idProofNumber`
                              )
                                ? "form-control sd-red"
                                : item?.idProofNumber !== ""
                                ? "form-control sd-voilet"
                                : "form-control"
                            }
                            value={item?.idProofNumber}
                            style={{textTransform: "none"}}
                            onChange={(e) => {
                              if (
                                /^[#.0-9a-zA-Z\s]+$/.test(e.target.value) ||
                                e.target.value === ""
                              ) {
                                if (
                                  err.find(
                                    (e) => e.type === `${i + 1}-idProofNumber`
                                  )
                                ) {
                                  let data = err.filter(
                                    (e) => e.type !== `${i + 1}-idProofNumber`
                                  );
                                  setErr(data);
                                }
                                handleChange(i, e, "idProofNumber");
                              }
                            }}
                          />
                          <p className="sd-EM mr-15 ">
                            {
                              err.find(
                                (e) => e.type === `${i + 1}-idProofNumber`
                              )?.msg
                            }
                          </p>
                        </div>
                        <div
                          className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 pr-0 mb-0"
                          style={{marginBottom: "0px !important"}}
                        >
                          <label>
                            Upload ID Proof<span className="sd-imp">*</span>
                          </label>

                          {item?.doc_file_url ? (
                            <div className="upload-button">
                              {item?.doc_file_url?.originalname
                                ? item?.doc_file_url?.originalname
                                : "Uploaded"}{" "}
                              <img
                                src={sd_trash}
                                alt="delete"
                                onClick={() => {
                                  let data = [...personStates];
                                  data[i]["doc_file_url"] = "";
                                  setPersonStates(data);
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {item?.doc_file_url === "" ? (
                            <input
                              id={`uploadInput${i}`}
                              name="upload-button"
                              style={{padding: "0px 12px"}}
                              type="file"
                              placeholder="Please attach a valid id proof"
                              className={
                                err?.find(
                                  (e) => e.type === `${i + 1}-doc_file_url`
                                )
                                  ? "form-control sd-red"
                                  : "form-control"
                              }
                              accept="image/png, image/jpeg,image/jpg,.pdf"
                              onChange={async (e) => {
                                if (
                                  err.find(
                                    (e) => e.type === `${i + 1}-doc_file_url`
                                  )
                                ) {
                                  let data = err.filter(
                                    (e) => e.type !== `${i + 1}-doc_file_url`
                                  );
                                  setErr(data);
                                }
                                let res = await handleFileUpload(e);

                                if (res) {
                                  handleChange(i, res, "doc_file_url");
                                }
                              }}
                            />
                          ) : (
                            ""
                          )}
                          <p className="sd-EM ">
                            {
                              err.find(
                                (e) => e.type === `${i + 1}-doc_file_url`
                              )?.msg
                            }
                          </p>
                        </div>{" "}
                      </div>
                    </div>
                    <hr className="clearfix"></hr>
                  </>
                );
              })}
              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pr-0">
                <p
                  className="ml--15"
                  style={{
                    color: "#181818",
                    fontWeight: "600",

                    whiteSpace: "pre-wrap",
                  }}
                >
                  Note: Pilgrims who book for Pratyaksha Seva should bring the
                  printed copy of their receipt.{" "}
                </p>
                <ol className="sd-terms-list">
                  {selectedArray[0]?.name === "Udayasthamana Seva"
                    ? udhayastamana.map((item, i) => {
                        return (
                          <li className="active" key={i + "terms-prs"}>
                            <a style={{paddingRight: "3px"}}>{item}</a>
                          </li>
                        );
                      })
                    : selectedArray[0]?.name === "Udayasthamana Seva" &&
                      pradhoshakala.map((item, i) => {
                        return (
                          <li className="active" key={i + "terms-prs"}>
                            <a style={{paddingRight: "3px"}}>{item}</a>
                          </li>
                        );
                      })}
                  {selectedArray[0]?.name !== "Udayasthamana Seva" &&
                    termsList.map((item, i) => {
                      return (
                        <li className="active" key={i + "terms-prs"}>
                          <a style={{paddingRight: "3px"}}>{item}</a>
                        </li>
                      );
                    })}
                  <li>
                    Only single person will be allowed to perform Single
                    Abhishekam ticket on one ticket{" "}
                    {selectedArray[0]?.name ===
                    "Samuhika Abhishekamulu (Single)"
                      ? `(Couples are not allowed)`
                      : ""}
                    .
                  </li>
                  <li>
                    Devotees must report just 15 minutes prior the Seva timings
                    at the ritual spot.
                  </li>
                </ol>

                <div
                  className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 sd-terms-checkbox"
                  style={{alignItems: "center"}}
                >
                  {" "}
                  <input
                    className="mr-5"
                    type="checkbox"
                    name=""
                    value={checkbox}
                    onChange={() => {
                      if (err.find((e) => e.type === `check`)) {
                        let data = err.filter((e) => e.type !== `check`);
                        setErr(data);
                      }
                      setCheckbox(!checkbox);
                    }}
                    checked={checkbox === true ? true : false}
                  />
                  <label
                    htmlFor=""
                    style={{marginBottom: "0px", position: "relative"}}
                  >
                    I agree to the{" "}
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedlanguage}/legalities/terms-and-conditions`
                        );
                      }}
                    >
                      Terms & Conditions
                    </a>{" "}
                    and the
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedlanguage}/legalities/privacy-policy`
                        );
                      }}
                    >
                      {" "}
                      Privacy Policy
                    </a>
                  </label>
                  <p className="sd-accept">
                    {err.find((e) => e.type === "check")?.msg}
                  </p>
                </div>
              </div>
              <hr className="clearfix"></hr>
              <div className="clearfix mt-50">
                <a
                  className="sd-btn-orange"
                  onClick={() => {
                    if (!loading) {
                      handleSubmit();
                    }
                  }}
                >
                  Confirm Booking{" "}
                  {loading ? (
                    <img
                      src={sd_btn_loading}
                      alt=".."
                      className="sd-arrow-btn ml-5 h-20"
                    />
                  ) : (
                    <img
                      className="sd-arrow-btn ml-5"
                      src={SD_Right_Arrow_white}
                    />
                  )}
                </a>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
export default Sd_dashboard_pratyakshaseva_seva_details;
