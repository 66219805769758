import React, {Suspense, lazy} from "react";
import {Route, Switch} from "react-router-dom";
import "./Utils/sd-styles/sd-app.scss";
import Sd_Home from "./pages/sd-home/sd-home";
import SD_Dashboard from "./pages/sd-dashboard";
import Sd_terms_and_conditions from "./pages/sd-misc/sd-terms-and-condotions";
import Sd_private_policy from "./pages/sd-misc/sd-private-policy";
import Sd_loading from "./Components/sd-common-components/sd-loading";
import Sd_about_general_information from "./pages/sd-about/sd-about-general-information";
import Sd_sevas_and_darshanam_paroksha_seva_nandeeshwara_abhishekam from "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-paroksha-seva-nandeeshwara-abhishekam";
import Sd_sevas_and_darshanam_paroksha_seva_mahanyasa_purvaka_rudrabhishekam from "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-paroksha-mahansaya-rudrabhishekam";
import Sd_sevas_and_darshanam_pratyakshaseva_abhishekam_at_garbhalayam from "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-abhishekam-at-garbhalayam";
import Sd_sevas_and_darshanam_pratyakshaseva_break_darsham from "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-bread-darshanam";
import Sd_sevas_and_darshanam_pratyakshaseva_udhayaastamana from "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-udhayaastamana";
import Sd_sevas_and_darshanam_pratyakshaseva_pradoshakaala from "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyaksha-seva-pradoshakaala";

//About---------------------
const Sd_about = lazy(() => import("./pages/sd-about/sd-about"));
const Sd_about_srisaila_devasthanam = lazy(() =>
  import("./pages/sd-about/sd-about-srisaila-devastanam")
);
const Sd_about_intellectual_properties = lazy(() =>
  import("./pages/sd-about/sd-about-intellectual-property")
);
const Sd_about_certifications = lazy(() =>
  import("./pages/sd-about/sd-about-certifications")
);
const Sd_about_main_temple = lazy(() =>
  import("./pages/sd-about/sd-about-main-temple")
);
const Sd_faqs_all = lazy(() =>
  import("./Components/sd-common-components/sd-faqs-all")
);

const Sd_about_srisaila_prabha = lazy(() =>
  import("./pages/sd-about/sd-about-srisaila-prabha")
);

const Sd_test = lazy(() => import("./pages/sd-misc/sd-tests"));

const Sd_support = lazy(() => import("./pages/sd-support/sd-support"));

//trust
const Sd_about_trusts = lazy(() => import("./pages/sd-about/sd-about-trusts"));
const Sd_about_trusts_swacha_srisailam = lazy(() =>
  import("./pages/sd-about/sd-about-trust-swachaa-srisailam")
);
const Sd_about_trusts_agama_patashala = lazy(() =>
  import("./pages/sd-about/sd-about-trusts-agama-patashala-trust")
);
const Sd_about_trusts_pranadhana_trust = lazy(() =>
  import("./pages/sd-about/sd-about-trusts-pranadhana_trust")
);
const Sd_about_trusts_anna_parsadam = lazy(() =>
  import("./pages/sd-about/sd-about-trusts-anna-prasadam")
);
const Sd_about_trusts_go_samrakhsana_trust = lazy(() =>
  import("./pages/sd-about/sd-about-trusts-go-samrakshsana-trust")
);
const Sd_about_trusts_sri_bhramaramba_mallikarjuna_swamyvarla_devasthanam =
  lazy(() =>
    import(
      "./pages/sd-about/sd-about-trusts-sri-bhramarambha-mallikarjuna-swamyvarla-devasthanam"
    )
  );

const Sd_about_12_jyotirlinga = lazy(() =>
  import("./pages/sd-about/sd-about-12-jyotirlinga")
);
const Sd_about_18_shakthi_peethas = lazy(() =>
  import("./pages/sd-about/sd-about-18-shakthi-peethas")
);

//Sevas-and-darshanam
const Sd_sevas_and_darshanam_paroksha_seva = lazy(() =>
  import("./pages/sd-sevas-and-darshanam/sd-sevas-and-darshanam-paroksha-seva")
);
const Sd_sevas_and_darshanam_pratyakshaseva = lazy(() =>
  import("./pages/sd-sevas-and-darshanam/sd-sevas-and-darshanam-pratyashaseva")
);
const Sd_sevas_and_darshanam_saswata_seva = lazy(() =>
  import("./pages/sd-sevas-and-darshanam/sd-sevas-and-darshanam-saswata-seva")
);
const Sd_sevas_and_darshanam = lazy(() =>
  import("./pages/sd-sevas-and-darshanam/sd-sevas-and-darshasnam")
);
//darshanam
const Sd_sevas_and_darshanam_seegra_darshanam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-darshanam/sd-sevas-and-darshanam-seegra-darshanam"
  )
);
const Sd_sevas_and_darshanam_sarva_darshanam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-darshanam/sd-sevas-and-darshanam-sarva-darshanam"
  )
);
const Sd_sevas_and_darshanam_darshanam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-darshanam/sd-sevas-and-darshanam-darshanam"
  )
);
const Sd_sevas_and_darshanam_athi_seegra_darshanam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-darshanam/sd-sevas-and-darshanam-athi-seegara-darshanam"
  )
);
//pratyaksha seva

const Sd_sevas_and_darshanam_pratyakshaseva_mrutyuanjayahomam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-maha-mrutyunjaya-homam"
  )
);
const Sd_sevas_and_darshanam_pratyakshaseva_samuhika_abhishekam_couple = lazy(
  () =>
    import(
      "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-samuhika-abhishekam-couple"
    )
);
const Sd_sevas_and_darshanam_pratyakshaseva_leela_kalyanotsavam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-leela-kalyanotsavam"
  )
);

const Sd_sevas_and_darshanam_pratyakshaseva_samuhika_abhishekam_single = lazy(
  () =>
    import(
      "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-samuhika-abhishekam-single"
    )
);
const Sd_sevas_and_darshanam_pratyakshaseva_abhishekam_alamkaramandapam_couple_only_alamkaradarshanam =
  lazy(() =>
    import(
      "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-abhishekam-alamkaramandapam-couple-only-alamkara-darshanam"
    )
  );
const Sd_sevas_and_darshanam_pratyakshaseva_chandi_homam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-chandi-homam"
  )
);
const Sd_sevas_and_darshanam_pratyakshaseva_ekanta_seva = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-ekanta-seva"
  )
);
const Sd_sevas_and_darshanam_pratyakshaseva_ganapathi_homam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-ganapathi-homam"
  )
);
const Sd_sevas_and_darshanam_pratyakshaseva_kalyanotsavam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-kalyanotsavam"
  )
);
const Sd_sevas_and_darshanam_pratyakshaseva_kumkuma_aservachanam_mandapam =
  lazy(() =>
    import(
      "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-kumkuma-aservachana-mandapam"
    )
  );
const Sd_sevas_and_darshanam_pratyakshaseva_kumkuma_pooja_sree_chakram = lazy(
  () =>
    import(
      "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-kumkuma-pooja-sree-chakram"
    )
);
const Sd_sevas_and_darshanam_pratyakshaseva_laksha_bilvarchakam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-laksha-bilvarchakam"
  )
);
const Sd_sevas_and_darshanam_pratyakshaseva_laksha_kumkumarchana = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-laksha-kumkumarchana"
  )
);

const Sd_sevas_and_darshanam_pratyakshaseva_mahanyasa_poorvaka_rudhrabhishekam =
  lazy(() =>
    import(
      "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-mahanyasa-poorvaka-rudhrabhishekam"
    )
  );
const Sd_gallery_full_view = lazy(() =>
  import("./Components/sd-common-components/sd-gallery-full-view")
);
const Sd_sevas_and_darshanam_pratyakshaseva_rudra_homam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-rudra-homam"
  )
);
const Sd_sevas_and_darshanam_pratyakshaseva_sarva_seva_pataakam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-sarva-seva-pataakam"
  )
);
const Sd_sevas_and_darshanam_pratyakshaseva_valli_devasena = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-valli-devasena-sameta-subramanyeswara-swamy-kalyanam"
  )
);
const Sd_sevas_and_darshanam_pratyakshaseva_vruddha_mallikarjuna_swamy_vaari_abhishekam =
  lazy(() =>
    import(
      "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-vruddha-mallikarjuna_swamy_vaari_abhishekam"
    )
  );
const Sd_sevas_and_darshanam_pratyakshaseva_suprabhata_seva = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-pratyaksha-seva/sd-sevas-and-darshanam-pratyakshaseva-suprabhata-seva"
  )
);
//paroksha seva
// const Sd_sevas_and_darshanam_paroksha_seva_varalakshmi_vratham = lazy(() =>
//   import(
//     "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-paroksha-seva-varalakshmi-vratham"
//   )
// );

const Sd_sevas_and_darshanam_paroksha_seva_ganapathi_homam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-paroksha-seva-ganapathi-homam"
  )
);
// const Sd_sevas_and_darshanam_paroksha_seva_sri_ganapathi_pooja = lazy(() =>
//   import(
//     "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-paroksha-seva-sri-ganapathi-pooja"
//   )
// );
const Sd_sevas_and_darshanam_paroksha_seva_kumkumarchana = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-paroksha-seva-kumkumarchana"
  )
);
const Sd_sevas_and_darshanam_paroksha_seva_mrityunjaya_homam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-paroksha-seva-mrityunjaya-homam"
  )
);
const Sd_sevas_and_darshanam_paroksha_seva_leela_kalyanotsavam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-paroksha-seva-leela-kalyanotsavam"
  )
);
const Sd_sevas_and_darshanam_paroksha_seva_veerabadraswamy = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-paroksha-seva-veerabhadraswamy"
  )
);
const Sd_sevas_and_darshanam_paroksha_seva_rudra_abhishekam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-paroksha-seva-rudra-abhishekam"
  )
);
const Sd_sevas_and_darshanam_paroksha_seva_valli_devasena_sameta_subramanyeshwara_swamy_kalyanam =
  lazy(() =>
    import(
      "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-paroksha-seva-valli-devasena-sameta-subramanyeshwara-swamy-kalyanam"
    )
  );
const Sd_sevas_and_darshanam_paroksha_seva_vedasirvachanam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-paroksha-seva-vedasirvachanam"
  )
);
const Sd_sevas_and_darshanam_paroksha_seva_chandi_homam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-parokshaseva-chandi-homam"
  )
);
const Sd_sevas_and_darshanam_paroksha_seva_ekanta_seva = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-parokshaseva-ekanta-seva"
  )
);
const Sd_sevas_and_darshanam_paroksha_seva_rudra_homam = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-and-darshanam-rudra-homam"
  )
);
const Sd_temple_dairy = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-temple-dairy")
);
//donations

const Sd_donations = lazy(() => import("./pages/sd-donation/sd-donations"));
const Sd_donations_anna_prasadam_trust = lazy(() =>
  import("./pages/sd-donation/sd-donations-anna-prasadam-trust")
);
const Sd_donations_agama_patashala_trust = lazy(() =>
  import("./pages/sd-donation/sd-donations-agama-patashala-trust")
);
const Sd_donations_srisaila_tv = lazy(() =>
  import("./pages/sd-donation/sd-donations-srisaila-tv")
);
const Sd_donations_go_samrakhsana_trust = lazy(() =>
  import("./pages/sd-donation/sd-donations-go-samrakshana-trust")
);
const Sd_donations_pranadhana_trust = lazy(() =>
  import("./pages/sd-donation/sd-donations-pranadana-trust")
);
const Sd_donations_donate_sri_bhramarambha_mallikarjuna_swamyvari_devasthanam_trust =
  lazy(() =>
    import(
      "./pages/sd-donation/sd-donations-sri-bhramaramba-mallikarjuna-swamyvari-devasthanam-trust"
    )
  );
const Sd_donations_swachaa_srisailam = lazy(() =>
  import("./pages/sd-donation/sd-donations-swachaa-srisailam")
);
const Sd_donations_e_hundi = lazy(() =>
  import("./pages/sd-donation/sd-donations-e-hundi")
);
//Online Booking

const Sd_online_booking = lazy(() =>
  import("./pages/sd-online-booking/sd-online-booking")
);

const Sd_online_booking_anna_prasadam_tickets = lazy(() =>
  import("./pages/sd-online-booking/sd-online-booking-anna-prasadam-tickets")
);

const Sd_online_booking_kalayana_mandapam_booking = lazy(() =>
  import(
    "./pages/sd-online-booking/sd-online-booking-kalayana-mandapam-booking"
  )
);
const Sd_online_booking_kalyana_katta_tickets = lazy(() =>
  import("./pages/sd-online-booking/sd-online-booking-kalyana-katta-tickets")
);

const Sd_online_booking_prasadam = lazy(() =>
  import("./pages/sd-online-booking/sd-online-booking-prasadam")
);
const Sd_online_booking_tour_packages = lazy(() =>
  import("./pages/sd-online-booking/sd-online-booking-tour-packages")
);
const Sd_online_booking_transportation = lazy(() =>
  import("./pages/sd-online-booking/sd-online-booking-transportation")
);
//Media Room

const Sd_media_room_press_hoc = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-press/sd-media-room-press-hoc")
);

const Sd_media_room_tenders = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-tenders")
);
const Sd_media_room = lazy(() => import("./pages/sd-media-room/sd-media-room"));
const Sd_media_room_events = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-events")
);
const Sd_media_room_media_kit = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-media-kit")
);
const Sd_media_room_media_queries = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-media-queries")
);
const Sd_media_room_news = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-news")
);
const Sd_media_room_newsletter = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-newsletter")
);
const Sd_media_room_photo_gallery = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-photo-gallery")
);
const Sd_media_room_press_coverage = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-press-coverage")
);
const Sd_media_room_press_releases = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-press-releases")
);
const Sd_media_room_publications = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-publications")
);
const Sd_media_room_rti_act = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-rti-act")
);
const Sd_media_room_video_gallery = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-video-gallery")
);
//volunteer-get involved
const Sd_get_involved = lazy(() =>
  import("./pages/sd-get-involved/sd-get-involved")
);
const Sd_get_involoved_volunteer_opurtunity = lazy(() =>
  import("./pages/sd-get-involved/sd-get-invloved-volunteer-oppurtunity")
);
const Sd_get_involved_csr_oppurtunity = lazy(() =>
  import("./pages/sd-get-involved/sd-get-involved-csr-oppurtunity")
);
const Sd_get_involved_influencers = lazy(() =>
  import("./pages/sd-get-involved/sd-get-involved-influencers")
);
const Sd_get_involved_ngos = lazy(() =>
  import("./pages/sd-get-involved/sd-get-involved-ngos")
);
//shop
const Sd_shop = lazy(() => import("./pages/sd-shop/sd-shop"));
//cart
const Sd_search = lazy(() => import("./pages/sd-search/sd-search"));
const Sd_cart = lazy(() => import("./pages/sd-cart/sd-cart"));
//support
const Sd_support_call_center = lazy(() =>
  import("./pages/sd-support/sd-support-call-center")
);
const Sd_support_connectivity = lazy(() =>
  import("./pages/sd-support/sd-support-conectivity")
);
const Sd_support_contactUs = lazy(() =>
  import("./pages/sd-support/sd-support-contacUs")
);
const Sd_support_facilities_to_pilgrims = lazy(() =>
  import("./pages/sd-support/sd-support-facilities-to-pilgrims")
);
const Sd_support_live_chat = lazy(() =>
  import("./pages/sd-support/sd-support-live-chat")
);
const Sd_support_faqs = lazy(() =>
  import("./pages/sd-support/sd-support-faqs")
);
const SD_Login = lazy(() => import("./pages/sd-login-register-pages/sd-login"));
const SD_Signup = lazy(() =>
  import("./pages/sd-login-register-pages/sd-singup")
);

const SD_Assistance = lazy(() => import("./pages/sd-assistance"));
const SD_Reset = lazy(() => import("./pages/sd-login-register-pages/sd-reset"));
const SD_Verify_OTP = lazy(() =>
  import("./pages/sd-login-register-pages/sd-verify-otp")
);
const Sd_sevas_and_darshanam_paroksha_seva_laksha_kumkumarchana = lazy(() =>
  import(
    "./pages/sd-sevas-and-darshanam/sd-paroksha-seva/sd-sevas-darshanam-paroksha-seva-laksha-kumkumarchana"
  )
);

const SD_Signup_Step_Two = lazy(() =>
  import("./pages/sd-login-register-pages/sd-register-step-two")
);
const SD_Dont_Remember = lazy(() =>
  import("./pages/sd-login-register-pages/sd-dont-remember")
);
// import {SD_Context} = lazy(() => import( "./context/sd-context";

const SD_Guest_SignUp = lazy(() =>
  import("./pages/sd-login-register-pages/sd-guest-login")
);
// import SD_Dashboard = lazy(() => import( "./pages/sd-dashboard";
const Sd_404 = lazy(() => import("./pages/sd-error-pages/sd-404"));

const Sd_about_the_temple_history = lazy(() =>
  import("./pages/sd-about/sd-about-the-temple-hitsory")
);
const Sd_about_the_temple = lazy(() =>
  import("./pages/sd-about/sd-about-the-temple")
);
const Sd_trademark = lazy(() => import("./pages/sd-misc/sd-trademarks"));
const Sd_faqs_Main = lazy(() => import("./pages/sd-help/sd-faqs"));
const Sd_Print_a_ticket = lazy(() =>
  import("./pages/sd-misc/sd-print-a-ticket")
);
const Sd_Routes = () => {
  // console.log("routes render");

  return (
    <Suspense fallback={<Sd_loading />}>
      <Switch>
        <Route exact path="/:lang/tests" component={Sd_test} />
        <Route exact path="/:lang/home" component={Sd_Home} />
        <Route exact path="/:lang/devotee-app/*" component={SD_Dashboard} />
        <Route exact path="/:lang/about" component={Sd_about} />
        <Route exact path="/:lang/devotee/signin" component={SD_Login} />
        <Route exact path="/:lang/devotee/signup" component={SD_Signup} />
        <Route
          exact
          path="/:lang/media-room/press/:type"
          component={Sd_media_room_press_hoc}
        />
        <Route
          exact
          path="/:lang/devotee/print-a-ticket"
          component={Sd_Print_a_ticket}
        />
        <Route
          exact
          path="/:lang/about/the-temple-history/:type"
          component={Sd_about_the_temple_history}
        />
        <Route
          exact
          path="/:lang/about/the-temple/:type"
          component={Sd_about_the_temple}
        />
        <Route
          exact
          path="/:lang/about/general-information/:type"
          component={Sd_about_general_information}
        />
        <Route
          exact
          path="/:lang/legalities/terms-and-conditions"
          component={Sd_terms_and_conditions}
        />
        <Route
          exact
          path="/:lang/legalities/privacy-policy"
          component={Sd_private_policy}
        />
        <Route exact path="/:lang/faqs/:type" component={Sd_faqs_Main} />
        <Route exact path="/:lang/support/faqs-all" component={Sd_faqs_all} />
        <Route
          exact
          path="/:lang/devotee/signup/verify"
          component={SD_Signup_Step_Two}
        />
        <Route
          exact
          path="/:lang/media-room/photo-gallery"
          component={Sd_gallery_full_view}
        />
        <Route
          exact
          path="/:lang/devotee/assistance"
          component={SD_Assistance}
        />
        <Route
          exact
          path="/:lang/devotee/reset/verify/:num"
          component={SD_Verify_OTP}
        />
        <Route exact path="/:lang/trademarks" component={Sd_trademark} />
        <Route exact path="/:lang/devotee/reset" component={SD_Reset} />
        <Route
          exact
          path="/:lang/devotee/reset/help"
          component={SD_Dont_Remember}
        />
        <Route exact path="/:lang/guestLogin" component={SD_Guest_SignUp} />
        <Route
          exact
          path="/:lang/about/srisaila-devasthanam"
          component={Sd_about_srisaila_devasthanam}
        />
        <Route
          exact
          path="/:lang/about/intellectual-property"
          component={Sd_about_intellectual_properties}
        />
        <Route
          exact
          path="/:lang/about/certifications"
          component={Sd_about_certifications}
        />
        <Route
          exact
          path="/:lang/about/main-temple"
          component={Sd_about_main_temple}
        />
        <Route
          exact
          path="./:lang/about/srisaila-prabha"
          component={Sd_about_srisaila_prabha}
        />
        <Route exact path="/:lang/about/trusts" component={Sd_about_trusts} />
        <Route
          exact
          path="/:lang/about/trusts/sri-bhramaramba-mallikarjuna-swamyvarla-devasthanam"
          component={
            Sd_about_trusts_sri_bhramaramba_mallikarjuna_swamyvarla_devasthanam
          }
        />
        <Route
          exact
          path="/:lang/about/trusts/anna-prasadam-trust"
          component={Sd_about_trusts_anna_parsadam}
        />
        <Route
          exact
          path="/:lang/about/trusts/aagama-patashala-trust"
          component={Sd_about_trusts_agama_patashala}
        />
        <Route
          exact
          path="/:lang/about/trusts/go-samrakshana-trust"
          component={Sd_about_trusts_go_samrakhsana_trust}
        />
        <Route
          exact
          path="/:lang/about/trusts/pranadana-trust"
          component={Sd_about_trusts_pranadhana_trust}
        />
        <Route
          exact
          path="/:lang/about/trusts/swachaa-srisailam"
          component={Sd_about_trusts_swacha_srisailam}
        />
        <Route
          exact
          path="/:lang/about/12-jyotirlingalu"
          component={Sd_about_12_jyotirlinga}
        />
        <Route
          exact
          path="/:lang/about/18-shakthi-peethalu"
          component={Sd_about_18_shakthi_peethas}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam"
          component={Sd_sevas_and_darshanam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva"
          component={Sd_sevas_and_darshanam_paroksha_seva}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva"
          component={Sd_sevas_and_darshanam_pratyakshaseva}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/saswata-seva"
          component={Sd_sevas_and_darshanam_saswata_seva}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/darshanam"
          component={Sd_sevas_and_darshanam_darshanam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/sarva-darshanam"
          component={Sd_sevas_and_darshanam_sarva_darshanam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/seegra-darshanam"
          component={Sd_sevas_and_darshanam_seegra_darshanam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/athi-seegra-darshanam"
          component={Sd_sevas_and_darshanam_athi_seegra_darshanam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/suprabhata-seva"
          component={Sd_sevas_and_darshanam_pratyakshaseva_suprabhata_seva}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/mrityunjaya-homam"
          component={Sd_sevas_and_darshanam_pratyakshaseva_mrutyuanjayahomam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/vruddha-mallikarjuna-swamy-vaari-abhishekam"
          component={
            Sd_sevas_and_darshanam_pratyakshaseva_vruddha_mallikarjuna_swamy_vaari_abhishekam
          }
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/sri-sakshi-ganapathi-homam"
          component={Sd_sevas_and_darshanam_pratyakshaseva_ganapathi_homam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/laksha-bilvarchana"
          component={Sd_sevas_and_darshanam_pratyakshaseva_laksha_bilvarchakam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/mahanyasa-purvaka-rudrabhishekam"
          component={
            Sd_sevas_and_darshanam_pratyakshaseva_mahanyasa_poorvaka_rudhrabhishekam
          }
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/kumkuma-poojasri-chakram"
          component={
            Sd_sevas_and_darshanam_pratyakshaseva_kumkuma_pooja_sree_chakram
          }
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/kumkumarchana-in-aseervachana-mandapam"
          component={
            Sd_sevas_and_darshanam_pratyakshaseva_kumkuma_aservachanam_mandapam
          }
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/kumkumarchana-antharalayam"
          component={
            Sd_sevas_and_darshanam_pratyakshaseva_kumkuma_aservachanam_mandapam
          }
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/leela-kalyanotsavam"
          component={Sd_sevas_and_darshanam_pratyakshaseva_leela_kalyanotsavam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/laksha-kumkumarchana"
          component={Sd_sevas_and_darshanam_pratyakshaseva_laksha_kumkumarchana}
        />
        {/* <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/sri-ganapathi-pooja"
          component={Sd_sevas_and_darshanam_paroksha_seva_sri_ganapathi_pooja}
        /> */}
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/valli-devasena-sameta-subramanyeswara-swamy-kalyanam"
          component={Sd_sevas_and_darshanam_pratyakshaseva_valli_devasena}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/rudra-homam"
          component={Sd_sevas_and_darshanam_pratyakshaseva_rudra_homam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/samuhika-abhishekamulu-single"
          component={
            Sd_sevas_and_darshanam_pratyakshaseva_samuhika_abhishekam_single
          }
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/abhishekam-alamkaramandapam-couple-only-alamkara-darshanam"
          component={
            Sd_sevas_and_darshanam_pratyakshaseva_abhishekam_alamkaramandapam_couple_only_alamkaradarshanam
          }
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/samuhika-abhishekamulu-couple"
          component={
            Sd_sevas_and_darshanam_pratyakshaseva_samuhika_abhishekam_couple
          }
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/chandi-homam"
          component={Sd_sevas_and_darshanam_pratyakshaseva_chandi_homam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/vip-break-darshanam"
          component={Sd_sevas_and_darshanam_pratyakshaseva_break_darsham}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/pradoshakala-seva"
          component={Sd_sevas_and_darshanam_pratyakshaseva_pradoshakaala}
        />{" "}
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/udayasthamana-seva"
          component={Sd_sevas_and_darshanam_pratyakshaseva_udhayaastamana}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/kalyanotsavam"
          component={Sd_sevas_and_darshanam_pratyakshaseva_kalyanotsavam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/ekantha-seva"
          component={Sd_sevas_and_darshanam_pratyakshaseva_ekanta_seva}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/abhishekam-at-garbhalayam"
          component={
            Sd_sevas_and_darshanam_pratyakshaseva_abhishekam_at_garbhalayam
          }
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/pratyaksha-seva/sarva-seva-pataakam"
          component={Sd_sevas_and_darshanam_pratyakshaseva_sarva_seva_pataakam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/ganapathi-homam"
          component={Sd_sevas_and_darshanam_paroksha_seva_ganapathi_homam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/rudra-homam"
          component={Sd_sevas_and_darshanam_paroksha_seva_rudra_homam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/mrityunjaya-homam"
          component={Sd_sevas_and_darshanam_paroksha_seva_mrityunjaya_homam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/kumkumarchana"
          component={Sd_sevas_and_darshanam_paroksha_seva_kumkumarchana}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/mahanyasa-purvaka-rudrabhishekam"
          component={
            Sd_sevas_and_darshanam_paroksha_seva_mahanyasa_purvaka_rudrabhishekam
          }
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/nandeeshwara-swamy-vari-visesharchana"
          component={
            Sd_sevas_and_darshanam_paroksha_seva_nandeeshwara_abhishekam
          }
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/rudraabhishekam"
          component={Sd_sevas_and_darshanam_paroksha_seva_rudra_abhishekam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/laksha-kumkumarchana"
          component={Sd_sevas_and_darshanam_paroksha_seva_laksha_kumkumarchana}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/valli-devasena-sameta-subramanyeswara-swamy-kalyanam"
          component={
            Sd_sevas_and_darshanam_paroksha_seva_valli_devasena_sameta_subramanyeshwara_swamy_kalyanam
          }
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/chandi-homam"
          component={Sd_sevas_and_darshanam_paroksha_seva_chandi_homam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/ekantha-seva"
          component={Sd_sevas_and_darshanam_paroksha_seva_ekanta_seva}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/leela-kalyanotsavam"
          component={Sd_sevas_and_darshanam_paroksha_seva_leela_kalyanotsavam}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/sri-bayalu-veerabadra-swami-vari-vishesha-puja-9th-june"
          component={Sd_sevas_and_darshanam_paroksha_seva_veerabadraswamy}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/sri-bayalu-veerabadra-swami-vari-vishesha-puja"
          component={Sd_sevas_and_darshanam_paroksha_seva_veerabadraswamy}
        />
        {/* <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/varalakshmi-vratham"
          component={Sd_sevas_and_darshanam_paroksha_seva_varalakshmi_vratham}
        /> */}
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/sri-bayalu-veerabhadra-swamy-vari-visesharchana"
          component={Sd_sevas_and_darshanam_paroksha_seva_veerabadraswamy}
        />
        <Route
          exact
          path="/:lang/sevas-and-darshanam/paroksha-seva/vedasirvachanam"
          component={Sd_sevas_and_darshanam_paroksha_seva_vedasirvachanam}
        />
        <Route exact path="/:lang/donations/" component={Sd_donations} />
        <Route
          exact
          path="/:lang/donations/e-hundi"
          component={Sd_donations_e_hundi}
        />
        <Route
          exact
          path="/:lang/donations/sbms-sri-bhramaramba-mallikarjuna-swamyvari-devasthanam-trust"
          component={
            Sd_donations_donate_sri_bhramarambha_mallikarjuna_swamyvari_devasthanam_trust
          }
        />
        <Route
          exact
          path="/:lang/donations/sbms-annaprasadam-trust"
          component={Sd_donations_anna_prasadam_trust}
        />
        <Route
          exact
          path="/:lang/donations/sbms-aagama-patashala-trust"
          component={Sd_donations_agama_patashala_trust}
        />
        <Route
          exact
          path="/:lang/donations/sbms-srisaila-tv"
          component={Sd_donations_srisaila_tv}
        />
        <Route
          exact
          path="/:lang/donations/sbms-gosamrakshana-trust"
          component={Sd_donations_go_samrakhsana_trust}
        />
        <Route
          exact
          path="/:lang/donations/sbms-pranadana-trust"
          component={Sd_donations_pranadhana_trust}
        />
        <Route
          exact
          path="/:lang/donations/sbms-swachh-srisailam"
          component={Sd_donations_swachaa_srisailam}
        />
        <Route
          exact
          path="/:lang/online-booking"
          component={Sd_online_booking}
        />
        <Route
          exact
          path="/:lang/online-booking/prasadam"
          component={Sd_online_booking_prasadam}
        />
        <Route
          exact
          path="/:lang/online-booking/kalyana-katta-tickets"
          component={Sd_online_booking_kalyana_katta_tickets}
        />
        <Route
          exact
          path="/:lang/online-booking/anna-prasadam-tickets"
          component={Sd_online_booking_anna_prasadam_tickets}
        />
        <Route
          exact
          path="/:lang/online-booking/transportation"
          component={Sd_online_booking_transportation}
        />
        <Route
          exact
          path="/:lang/online-booking/kalyana-mandapam-booking"
          component={Sd_online_booking_kalayana_mandapam_booking}
        />
        <Route
          exact
          path="/:lang/online-booking/tour-packages"
          component={Sd_online_booking_tour_packages}
        />
        <Route exact path="/:lang/media-room" component={Sd_media_room} />
        <Route
          exact
          path="/:lang/media-room/media-kit"
          component={Sd_media_room_media_kit}
        />
        <Route
          exact
          path="/:lang/media-room/gallery/photo-gallery"
          component={Sd_media_room_photo_gallery}
        />
        <Route
          exact
          path="/:lang/media-room/gallery/video-gallery"
          component={Sd_media_room_video_gallery}
        />
        <Route
          exact
          path="/:lang/media-room/whats-new/events"
          component={Sd_media_room_events}
        />
        <Route
          exact
          path="/:lang/media-room/whats-new/publications"
          component={Sd_media_room_publications}
        />
        <Route
          exact
          path="/:lang/media-room/whats-new/temple-diary"
          component={Sd_temple_dairy}
        />
        <Route
          exact
          path="/:lang/media-room/whats-new/newsletter"
          component={Sd_media_room_newsletter}
        />
        <Route
          exact
          path="/:lang/media-room/tenders"
          component={Sd_media_room_tenders}
        />
        <Route
          exact
          path="/:lang/media-room/rti-act"
          component={Sd_media_room_rti_act}
        />
        <Route
          exact
          path="/:lang/media-room/press/press-releases"
          component={Sd_media_room_press_releases}
        />
        <Route
          exact
          path="/:lang/media-room/press/temple-news"
          component={Sd_media_room_news}
        />
        <Route
          exact
          path="/:lang/media-room/press/press-coverage"
          component={Sd_media_room_press_coverage}
        />
        <Route
          exacts
          path="/:lang/media-room/press/media-queries"
          component={Sd_media_room_media_queries}
        />
        <Route exact path="/:lang/volunteer" component={Sd_get_involved} />
        <Route
          exact
          path="/:lang/get-involved/volunteer-oppurtunity"
          component={Sd_get_involoved_volunteer_opurtunity}
        />
        <Route
          exact
          path="/:lang/get-involved/influencers"
          component={Sd_get_involved_influencers}
        />
        <Route
          exact
          path="/:lang/get-involved/ngos"
          component={Sd_get_involved_ngos}
        />
        <Route
          exact
          path="/:lang/get-involved/csr-oppurtunity"
          component={Sd_get_involved_csr_oppurtunity}
        />
        <Route exact path="/:lang/shop" component={Sd_shop} />
        <Route exact path="/:lang/cart" component={Sd_cart} />
        <Route exact path="/:lang/search" component={Sd_search} />
        <Route
          exact
          path="/:lang/support/call-center"
          component={Sd_support_call_center}
        />
        <Route
          exact
          path="/:lang/support/contactUs"
          component={Sd_support_contactUs}
        />
        <Route
          exact
          path="/:lang/support/support-faqs"
          component={Sd_support_faqs}
        />
        <Route
          exact
          path="/:lang/support/live-chat"
          component={Sd_support_live_chat}
        />
        <Route
          exact
          path="/:lang/support/facilities-to-pilgrims"
          component={Sd_support_facilities_to_pilgrims}
        />
        <Route
          exact
          path="/:lang/support/connectivity"
          component={Sd_support_connectivity}
        />
        <Route exact path="/:lang/support" component={Sd_support} />
        <Route path="*" component={Sd_404} />
      </Switch>
    </Suspense>
  );
};
export default Sd_Routes;
